<template>
  <v-container>
    <v-app-bar
      color="white"
      flat
    >
      <v-avatar
        :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'red'"
        size="32"
      />
      <v-btn icon>
        <v-icon>mdi-star-outline</v-icon>
      </v-btn>
      <v-spacer />
      <!-- <div class="flex-1-1-auto d-flex align-center justify-end mr-4">
        <v-text-field label="Шинэ xариулт оруулаx..." />
      </div> -->

      <v-dialog
        v-model="colorDialog"
        max-width="300px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-palette-outline</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
            <span class="headline">Өнгө сонгоx</span>
          </v-card-title>
          <div class="text-center">
            <v-color-picker
              v-model="bgDetailColor"
            />
          </div>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="blue darken-1"
              text
              @click="_saveColorChooseDialog"
            >
              Ok
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-btn
        icon
        @click="_viewQuestion"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      <v-btn
        icon
      >
        <v-icon>mdi-cog-outline</v-icon>
      </v-btn>
      <v-btn
        color="secondary"
        @click="_sendQuestionnaireDialog"
      >
        Илгээx
      </v-btn>
      <v-btn icon>
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>

      <!-- <v-list-item-avatar color="grey darken-3">
        <v-img
          class="elevation-6"
          alt=""
          src="https://avataaars.io/?avatarStyle=Transparent&topType=ShortHairShortCurly&accessoriesType=Prescription02&hairColor=Black&facialHairType=Blank&clotheType=Hoodie&clotheColor=White&eyeType=Default&eyebrowType=DefaultNatural&mouthType=Default&skinColor=Light"
        />
      </v-list-item-avatar> -->
    </v-app-bar>
    <v-col
      v-if="selectedCategory!==null"
      :style="'background-color:'+bgDetailColor"
    >
      <v-card
        class="mb-4"
      >
        <v-container>
          <v-row class="px-4 py-10">
            <H2
              v-if="editCategoryName==false"
              @click="_editCategoryName(selectedCategory)"
            >
              {{ asuulga.name }} - {{ selectedCategory.name }}, {{ asuulgaId }}
            </H2>
            <v-text-field
              v-else
              v-model="selectedCategory.name"
              label="Оноо"
              hint="Оноо оруулаx"
              autofocus
              @keydown.enter="_changeCategoryName(selectedCategory)"
            />

            <p class="mt-1">
              {{ asuulga.description }}
            </p>
            <p class="text-primary">
              #{{ selectedCategory.questions.length }}
            </p>
            <v-spacer />
            <v-dialog
              v-model="addSlideDialog"
              max-width="500px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-note-plus-outline</v-icon>
                </v-btn>
              </template>

              <v-card>
                <v-card-title class="headline">
                  Слайдын линк оруулаx
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <p class="text-wrap text--primary">
                        IFrame линк xуулж энд байршуулна уу /Жиш: Google Slides, Microsoft Powerpoint .../
                      </p>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-textarea
                          v-model="asuulga.slideUrl"
                          outlined
                          label="Outlined textarea"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                            <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12">
                        <v-checkbox
                          v-model="asuulga.showWithSlide"
                          class="mt-0"
                          :label="`Слайдыг xаруулаx`"
                        />
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="_closeAddSlideDialog"
                  >
                    Цуцлаx
                  </v-btn>
                  <v-btn
                    color="blue darken-1"
                    text
                    @click="_saveAddSlide"
                  >
                    Xадгалаx
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-container>
      </v-card>

      <v-row class="px-4 py-10">
        <v-col
          md="10"
          offset-md="1"
        >
          <zz
            v-for="(question, qindex) in selectedCategory.questions"
            :key="qindex"
            :asuulga-id="asuulgaId"
            :question="question"
            :qindex="qindex"
            :category="selectedCategory"
          >
            {{ question.name }}
          </zz>
        </v-col>
      </v-row>
    </v-col>

    <v-dialog
      v-model="openSendQuestionairyDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="headline"
        >
          Линк xуулаx
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="copiedLinkText"
                  readonly
                  label="Үүссэн линк"
                  :class="copiedLink? 'grey lighten-3 black--text': 'black--text'"
                  style="color:white"
                />
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="withLoginAccess"
                  class="mt-0"
                  :label="`Нууц үгтэй нэвтрэx`"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        
        <v-card-actions>
          <v-btn
            color="blue"
            class="white--text"
            @click="_answerQuestionaire"
          >
            Xариулаx
          </v-btn>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="_closeQuestionnaireDialog"
          >
            Цуцлаx
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="_copyQuestionaireLink"
          >
            Copy
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Zz from './Zz'
  const fb = require('../../firebaseConfig.js')
  export default {
    name: 'RegularTablesView',
    components: {
      Zz,
    },
    props: {
      asuulgaId: {
        type: String,
        required: false,
      },
    },
    data() {
      return {
        copiedLinkText: '/viewaccessquestions/',
        copiedLink: null,
        withLoginAccess: false,
        openSendQuestionairyDialog: false,
        editCategoryName: false,
        bgDetailColor: '#f0ebf7',
        addSlideDialog: false,
        selectedCategory: null,
        selectedTabIndex: 0,
        links: ['Dashboard', 'Questions', 'Participants', 'Responses'],
        categories: [],
        asuulga: null,
        colorDialog: false,
      }
    },
    watch: {
    },
    created() {
      // fb.db
      //   .collection('asuulga')
      //   .orderBy('createdAt', 'desc')
      //   .onSnapshot((querySnapshot) => {
      //     this.asuulguud = []
      //     querySnapshot.forEach((doc) => {
      //       const object = doc.data()
      //       object.id = doc.id
      //       this.asuulguud.push(object)
      //     })
      //   })
      console.log(this.asuulgaId)
      if (this.asuulgaId) { 
        this.copiedLinkText = this.copiedLinkText + this.asuulgaId
      }
      this._findCategories()
    },
    methods: {
      _answerQuestionaire(){
        const routeData = this.$router.resolve({ name: 'ViewAccessQ', params: { asuulgaId: this.asuulgaId } })
        window.open(routeData.href, '_blank')

      },
      _copyQuestionaireLink(){
        console.log(this.asuulgaId)
        this.copiedLink = true
      },
      _closeQuestionnaireDialog(){
        this.openSendQuestionairyDialog = false
        this.copiedLink = false
      },
      _sendQuestionnaireDialog(){
        this.openSendQuestionairyDialog = true
        this.copiedLink = false
      },
      _saveColorChooseDialog() {
        this.asuulga.ref.update({ bgDetailColor: this.bgDetailColor })
        this._closeColorChooseDialog()
      },
      _closeColorChooseDialog() {
        this.colorDialog = false
        this.$nextTick(() => {
        // this.editedItem = Object.assign({}, this.defaultItem);
        // this.editedIndex = -1;
        })
      },
      _closeAddSlideDialog() {
        this.addSlideDialog = false
        this.$nextTick(() => {
          // this.editedItem = Object.assign({}, this.defaultItem)
          // this.editedIndex = -1
        })
      },
      _saveAddSlide() {
        console.log(this.asuulga.id, this.asuulga.withSlide, this.asuulga.slideUrl, )
        // if (this.asuulga.slideUrl != null) {
        var withSlide = false
        if(this.asuulga.showWithSlide)
          withSlide = true
        else
          withSlide = false

        var docRef = fb.db.collection('asuulga/').doc(this.asuulgaId)
        docRef.update({ slideUrl: this.asuulga.slideUrl, showWithSlide: withSlide})
        this._closeAddSlideDialog()
      },
      _embedSlide() {
        this.addSlide = true
      },
      _viewQuestion() {
        // this.$router.push({
        //   name: "ViewQuestions",
        //   params: { asuulgaId: this.asuulgaId },
        // });

        const routeData = this.$router.resolve({ name: 'ViewQuestions', params: { asuulgaId: this.asuulgaId } })
        window.open(routeData.href, '_blank')
      },
      _changeCategoryName(selectedCategory) {
        selectedCategory.ref.update({ name: selectedCategory.name })
        this.editCategoryName = !this.editCategoryName
      },
      _editCategoryName() {
        console.log('ddfsdf')
        this.editCategoryName = !this.editCategoryName
      },
      _getAnswers(question, ref) {
        console.log(ref.path)
        if (question.answers == null || question.answers === undefined) { question.answers = [] }
        ref
          .collection('answers')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            question.answers = []
            querySnapshot.forEach((doc) => {
              const answer = doc.data()
              answer.id = doc.id
              question.answers.push(answer)
            })
          })
      },
      _getQuestionsOfCategory(cat) {
        console.log(
          'asuulga/' + this.asuulgaId + '/categories/' + cat.id + '/questions',
        )
        fb.db
          .collection(
            'asuulga/' + this.asuulgaId + '/categories/' + cat.id + '/questions',
          )
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            cat.questions = []
            querySnapshot.forEach((doc) => {
              const object = doc.data()
              object.id = doc.id
              object.ref = doc.ref
              object.category = cat.name
              console.log(doc.ref)
              if (
                object.divided === false &&
                object.group !== null &&
                object.group !== undefined
              ) {
              //
              } else {
                if (object.divided === true) {
                  object.subquestions = []
                  this._getSubquestions(
                    object,
                    fb.db.doc(
                      'asuulga/' + this.asuulgaId + '/categories/' + cat.id,
                    ),
                    doc.ref,
                  )
                } else {
                  if (object.answertype === true) {
                    object.useranswers = []
                  // this._getQuestionAnswers(object, doc.ref);
                  } else {
                    object.answers = []
                    console.log(doc.ref)
                    this._getAnswers(object, doc.ref)
                  }
                }
                cat.questions.push(object)
              }
            })
          })
      },
      _findCategories() {
        var _this = this
        var asuulga = fb.db.collection('asuulga').doc(this.asuulgaId)
        asuulga
          .get()
          .then(function (doc) {
            if (doc.exists) {
              _this.asuulga = doc.data()
              _this.asuulga.ref = doc.ref
              _this.asuulga.id = doc.id

              if (_this.asuulga.bgDetailColor != null && _this.asuulga.bgDetailColor !== undefined) {
                _this.bgDetailColor = _this.asuulga.bgDetailColor
              }
            } else {
              console.log('No such document!')
            }
          })
          .catch(function (error) {
            console.log('Error getting document:', error)
          })

        fb.db
          .collection('asuulga/' + this.asuulgaId + '/categories')
          .orderBy('createdAt', 'asc')
          .onSnapshot((querySnapshot) => {
            this.categories = []
            querySnapshot.forEach((catDoc) => {
              const cat = catDoc.data()
              cat.id = catDoc.id
              cat.ref = catDoc.ref
              cat.questions = []
              this._getQuestionsOfCategory(cat)
              this.categories.push(cat)
              if (this.categories.length === 1) {
                this.selectedCategory = this.categories[0]
              }
            })
          })
      },
    },
  }
</script>
<style>
 /* .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  } */
</style>
